<template>
  <div>
    <b-card-code>
      <b-card-text class="mb-0">
        <h3>{{ name }}</h3>
        <span>Assigned Values</span>
      </b-card-text>
      <div class="demo-inline-spacing">
        <!-- all values of attrbuite showed to delete -->
        <div v-for="(value,index) in attrbuiteAssignedValue" :key="index">
          <b-button
            variant="outline-danger"
            @click="deletePer(value.id, value.name)"
            :disabled="attrbuiteAssignedValue.length == 1"
          >
            <span class="align-middle">{{ value.name }}</span>
            <feather-icon icon="XIcon" class="ml-50" />
          </b-button>
        </div>
      </div>
    </b-card-code>
    <!-- success handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showSuccessleAlert"
      @dismissed="showSuccessAlert = false"
      variant="success"
    >
      <h4 class="alert-heading">
        Success
      </h4>
      <div class="alert-body">
        <span>{{ success_message }}</span>
      </div>
    </b-alert>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="values in errors_back" :key="values">
                    <li v-for="value in values" :key="value">{{value}}</li>
                </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
export default {
  props: ["attributeID"],
  components: {
    BCardCode,
  },
  
  data() {
    return {
      id: 0,
      attrbuiteName: "",
      attrbuiteAssignedValue: [],
      success_message: "",
      showDismissibleAlert: false,
      showSuccessleAlert: false,
      errors_back: [],
      errors_assign: {},
      option: [],
      dir: "ltr",
      selected: [],
      values: [],
      name: "",
    };
  },
  methods: {
    // deletePer function to delete revoke value from attrbuite
    deletePer(id, name) {
      this.values.push(id);
      this.$bvModal
        .msgBoxConfirm(
          "Please confirm that you want to delete (" + name + ").",
          {
            title: "Are you sure?",
            size: "sm",
            okVariant: "danger",
            okTitle: "Delete",
            cancelTitle: "Cancel",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            axios
              .get(
                "items-guide/" +
                  this.$route.params.id +
                  "/values/" +
                  id +
                  "/detach"
              )
              .then((result) => {
                this.$swal({
                  position: "center",
                  icon: "success",
                  title: "Your work has been saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.attrbuiteAssignedValue = [];
                this.fetchData();
              })
              .catch((err) => {
                this.$swal({
                  position: "center",
                  icon: "error",
                  title: "Error!",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.errors_back.length = 0;
                if (err.response.data.data != null) {
                  if (this.isString(err.response.data.data)) {
                    this.errors_back.push({
                      error: err.response.data.data,
                    });
                  } else {
                    this.errors_back = err.response.data.data;
                  }
                  this.showDismissibleAlert = true;
                } else {
                  this.errors_back = [];
                  this.errors_back.push({
                    error: "internal server error",
                  });
                  this.showDismissibleAlert = true;
                }
              });
          }
        });
    },
    fetchData() {
      axios
        .get("items-guide/" + this.$route.params.id)
        .then((result) => {
          const data = result.data.data;
          // this.attrbuiteName = data.name
          for (let index in data.attributes) {
            //console.log(this.$route.params.attr_id);
            if (data.attributes[index].id == this.$route.params.attr_id) {
              this.name = data.attributes[index].translation.name;
              for (let ind in data.attributes[index].itemValues) {
                this.attrbuiteAssignedValue.push({
                  id: data.attributes[index].itemValues[ind].id,
                  name: data.attributes[index].itemValues[ind].translation.name,
                });
              }
            }
          }
        })
        .catch((err) => {
          this.errors_back.length = 0;
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              });
            } else {
              this.errors_back = err.response.data.data;
            }
            this.showDismissibleAlert = true;
          } else {
            this.errors_back = [];
            this.errors_back.push({
              error: "internal server error",
            });
            this.showDismissibleAlert = true;
          }
        });
    },
    isString(value) {
      return typeof value === "string" || value instanceof String;
    },
  },
  created() {
    // request attrbuite data
    //console.log(this.$route.params.attr_id);
    this.fetchData();

    // // request all values data
    // axios.get('attributes/'+ this.$route.params.attr_id +'/values/get').then((result) => {
    //     const data = result.data.data
    //     for (let index in data) {
    //         if (!this.attrbuiteAssignedValue.includes(data[index].name)) {
    //             this.option.push({
    //                 value: data[index].id,
    //                 text: data[index].name
    //             })
    //         }
    //     }
    // }).catch((err) => {
    //     this.errors_back.length = 0
    //     if (err.response.data.data != null) {
    //         if (this.isString(err.response.data.data)) {
    //             this.errors_back.push({
    //                 error: err.response.data.data
    //             })
    //         } else {
    //             this.errors_back = err.response.data.data
    //         }
    //         this.showDismissibleAlert = true
    //     } else {
    //         this.errors_back = []
    //         this.errors_back.push({
    //             error: 'internal server error'
    //         })
    //         this.showDismissibleAlert = true
    //     }
    // });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
